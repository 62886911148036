import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreActions } from '../hooks';

const CloseModal = () => {
  const history = useHistory();
  const changeContentType = useStoreActions(
    (actions) => actions.contentForm.changeType,
  );
  return (
    <div className="absolute top-0 right-0">
      <svg
        onClick={() => {
          history.goBack();
          changeContentType('');
        }}
        className="h-4 w-4 md:w-8 md:h-8 mr-5 mt-5 text-gray-500 cursor-pointer"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default CloseModal;

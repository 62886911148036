import React, { useContext } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { useStoreActions } from '../hooks';
import { useUserInfo } from '../hooks/hook.useUserInfo';
import { AppContext } from './AppContext';
import MainSwitch from './MainSwitch';

const NavLi = (props: React.PropsWithChildren<React.ReactNode>) => {
  return (
    <li className="group">
      <div>{props.children}</div>
      <div className=" md:group-hover:scale-100 scale-0 border border-gray-300 transform duration-200"></div>
    </li>
  );
};

const toShortName = (name: string) => {
  if (name?.split('.').length <= 2) {
    return name[0];
  }
  return name.split('.')[0][0] + name.split('.')[1][0] ?? '';
};

const MainRouter = () => {
  const { imageUrl } = useContext(AppContext);
  const userInfo = useUserInfo();
  const logout = useStoreActions((actions) => actions.currentUser.logout);
  return (
    <Router>
      <div
        className="bg-gradient-to-br from-green-900 to-green-700 h-screen overflow-x-hidden transform duration-500 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className="flex justify-between items-center py-3 px-3">
          <div className="">
            {!userInfo.isGuest && (
              <div
                className="h-10 w-10 cursor-pointer text-gray-200 border-gray-300 border-2 hover:bg-gray-200
             hover:text-gray-800 transition duration-200 rounded-full p-2 flex justify-center items-center uppercase font-bold"
              >
                {toShortName(userInfo.info.unique_name)}
              </div>
            )}
          </div>
          {!userInfo.isGuest && (
            <nav className="flex flex-grow">
              <ul className="flex flex-grow justify-center text-xl text-gray-200">
                <NavLi>
                  <Link to="/">
                    <div
                      className="text-2xl md:text-4xl text-gray-200 font-extrabold opacity-75"
                      style={{ textShadow: 'rgba(0, 0, 0, 0.5) 0 0 100px' }}
                    >
                      calendoo
                    </div>
                  </Link>
                </NavLi>
              </ul>
            </nav>
          )}
          <div className="flex justify-end">
            <div
              className="h-10 w-10 cursor-pointer text-gray-100 border-gray-100 border-2 hover:bg-gray-200
             hover:text-gray-800 transition duration-200 rounded-full p-2"
              onClick={() => {
                logout();
              }}
            >
              <svg
                className="fill-current cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <MainSwitch />
      </div>
    </Router>
  );
};

export default MainRouter;

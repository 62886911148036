import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Content } from 'src/Store';
import SimpleContent from './Content';

const DraggableContent: React.FC<{
  content: Content;
  isLast: boolean;
  i: number;
}> = ({ content, isLast, i }) => {
  return (
    <Draggable key={content.id} draggableId={content.id.toString()} index={i}>
      {(provided, snapshot) => (
        <SimpleContent
          content={content}
          i={i}
          isLast={isLast}
          provided={provided}
          snapshot={snapshot}
        />
      )}
    </Draggable>
  );
};

export default DraggableContent;

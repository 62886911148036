import React from 'react';
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../hooks';
import ContentIcon from './ContentIcon';
import ContentForm from './ContentForm';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { getDoor } from './Api';
import moment from 'moment';
import CalendooModal from './CalendooModal';
import CloseModal from './CloseModal';
import DraggableContent from './DraggableContent';

const Contents = () => {
  const { doorId } = useParams();
  const history = useHistory();
  const idInt = parseInt(doorId ?? '');

  const contents = useStoreState((state) =>
    state.contents.items.filter((c) => c.doorId == idInt),
  );
  const getContentsApi = useStoreActions(
    (actions) => actions.contents.getContentsApi,
  );
  const pushContents = useStoreActions(
    (actions) => actions.contents.pushContents,
  );
  const changeContentType = useStoreActions(
    (actions) => actions.contentForm.changeType,
  );
  const reorderContentsApi = useStoreActions(
    (actions) => actions.contents.reorderContentsApi,
  );
  const getDoorQuery = useQuery(['getDoor', { id: doorId }], getDoor);
  const currentDoor = getDoorQuery.data;
  const contentType = useStoreState((state) => state.contentForm.contentType);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    reorderContentsApi({
      id: idInt,
      oldIndex: result.source.index,
      newIndex: result.destination.index,
    }).then((res) => pushContents(res));
  };

  useEffect(() => {
    getContentsApi(idInt).then((res) => pushContents(res));
  }, []);

  return (
    <CalendooModal
      isOpen={true}
      onRequestClose={() => {
        history.goBack();
        changeContentType('');
      }}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="contents">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={
                    'h-128 w-128 md:h-168 l:w-168 max-w-xs s:max-w-none scrolling-touch overflow-x-hidden p-3'
                  }
                >
                  <CloseModal />
                  <div className="flex flex-1 h-full flex-col text-center items-center w-full">
                    <div className="text-gray-800">
                      <span className="text-2xl md:text-4xl font-bold">
                        {getDoorQuery.data &&
                          moment(currentDoor?.dueDate).format('D.')}
                      </span>
                      <span className="text-xl md:text-2xl">
                        {getDoorQuery.data &&
                          moment(currentDoor?.dueDate).format(' MMMM')}
                      </span>
                    </div>
                    {
                      <>
                        {contents.length === 0 && (
                          <>
                            <div className="leading-none text-xl md:text-3xl text-gray-800 font-bold tracking-normal mt-5">
                              Noch keine Inhalte vorhanden
                            </div>
                            <div className="text-gray-700 text-base md:text-lg mt-1">
                              Wähle unten den gewünschen Inhalt aus
                            </div>
                          </>
                        )}

                        <div className="flex flex-grow w-full flex-col justify-between items-center mt-5">
                          {!(contents.length > 0) && (
                            <svg
                              className="fill-current text-gray-800 h-12 w-12 md:h-16 md:w-16 opacity-100 mt-5"
                              strokeWidth="0.2"
                              style={{
                                animation: 'translationPulse 1s ease-out',
                                animationIterationCount: 'infinite',
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                d="M9 16.172l-6.071-6.071-1.414 1.414L10 20l.707-.707 7.778-7.778-1.414-1.414L11 16.172V0H9z"
                              />
                            </svg>
                          )}
                          <div>
                            {contents
                              .sort((a, b) => a.sortOrder - b.sortOrder)
                              .map((content, i) => {
                                return (
                                  <DraggableContent
                                    key={content.id}
                                    content={content}
                                    isLast={i === contents.length - 1}
                                    i={i}
                                  />
                                );
                              })}
                            {provided.placeholder}
                            <div className="flex justify-center">
                              {contentType !== '' && <ContentForm id={idInt} />}
                            </div>
                          </div>
                          <div className="max-w-lg flex justify-between items-center w-full h-32 flex-shrink-0">
                            <ContentIcon icon="text" />
                            <ContentIcon icon="file" />
                            <ContentIcon icon="link" />
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </motion.div>
      </AnimatePresence>
    </CalendooModal>
  );
};

export default Contents;

import React, { useRef, useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../hooks';
import { getCalendar, getFiles } from './Api';
import Dropzone from './Dropzone';
import PrimaryButton from './PrimaryButton';

interface ContentFormProps {
  id: number;
}

const ContentForm = (props: React.PropsWithChildren<ContentFormProps>) => {
  const { calendarId }: { calendarId?: string | undefined } = useParams();
  const getFilesQuery = useQuery(['getFiles', { id: calendarId }], getFiles);
  const getCalendarQuery = useQuery(
    ['getCalendar', { id: calendarId }],
    getCalendar,
  );

  const currentCalendar = getCalendarQuery.data;
  const existentFiles = getFilesQuery.data;

  const fileSizeSum = existentFiles
    ?.map((f) => f?.fileSize ?? 0)
    .reduce((d, cur) => {
      return d + cur;
    }, 0);
  const fileSizeLimit = currentCalendar?.fileSizeLimit;

  const contentType = useStoreState((state) => state.contentForm.contentType);
  const contents = useStoreState((state) =>
    state.contents.items.filter((c) => c.doorId == props.id),
  );
  const setContent = useStoreActions(
    (actions) => actions.contentForm.setContent,
  );
  const changeContentType = useStoreActions(
    (actions) => actions.contentForm.changeType,
  );

  const formContent = useStoreState((state) => state.contentForm.content);

  const [rowsCount, setRowsCount] = useState(1);

  const textArea = useRef(null);

  const addContent = useStoreActions(
    (actions) => actions.contents.uploadContentApi,
  );

  return (
    <div className={'my-5 ' + (contents.length > 0 ? 'mt-0' : '')}>
      {['file'].includes(contentType) && (
        <div className="w-56 md:w-128">
          <div className="text-xs md:text-base py-1 text-gray-700">
            Füge hier Bilder, Videos oder Audiodateien ein.
          </div>
          <div className={' flex justify-center items-center  '}>
            <Dropzone
              doorId={props.id}
              fileSizeLimit={fileSizeLimit}
              fileSizeSum={fileSizeSum}
            />
          </div>
        </div>
      )}
      {contentType === 'text' && (
        <div className="w-56 md:w-128">
          <div className="text-xs md:text-base text-left text-gray-700 py-1">
            Füge einen beliebig langen Text ein.
          </div>
          <textarea
            value={formContent}
            ref={textArea}
            rows={rowsCount}
            className={`w-full h-full bg-white rounded-lg border focus:outline-none border-gray-400 resize-none p-2
              text-gray-800 text-lg font-medium font-sourceSansPro text-center`}
            onChange={(e) => {
              setContent(e.target.value);
              setRowsCount(e.target.value.split('\n').length);
            }}
          ></textarea>
        </div>
      )}
      {contentType === 'link' && (
        <div className="w-56 md:w-128">
          <div className="text-xs md:text-base text-left text-gray-700 py-1">
            Kopiere einen Link aus dem Internet hierher. Einige Links betten
            automatisch den Inhalt in dein Türchen ein (z.b. Youtube oder
            Spotify).
          </div>
          <div className="flex flex-row items-center bg-white rounded-lg border border-gray-400 w-full">
            <svg
              className={'fill-current h-6 mx-3 text-gray-500 flex-shrink'}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                clipRule="evenodd"
              />{' '}
            </svg>
            <input
              onChange={(e) => setContent(e.target.value)}
              className={
                'w-full h-12 flex-grow bg-white rounded-lg focus:outline-none'
              }
              value={formContent}
            />
          </div>
        </div>
      )}
      {formContent != '' && (
        <div className="w-56 md:w-full mt-3">
          <PrimaryButton
            caption="Speichern"
            onClickFunction={() =>
              addContent({
                doorId: props.id,
                contentType: contentType,
                formContent: formContent,
              }).then(() => {
                setContent('');
                changeContentType('');
                queryCache.invalidateQueries('getDoors');
              })
            }
          />
        </div>
      )}
    </div>
  );
};

export default ContentForm;

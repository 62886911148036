import moment from 'moment';
import React from 'react';
import { Calendar } from '../Store';
import { isToday } from './utils';

const CalendarListItem: React.FC<{ calendar: Calendar }> = ({ calendar }) => {
  const calendarFormat = 'D.M.YY';
  const calendarBegin = moment(calendar.begin).format(calendarFormat);
  const calendarEnd = moment(calendar.end).format(calendarFormat);

  return (
    <div
      className="p-3 max-w-xs mx-auto rounded-lg border border-gray-200 cursor-pointer md:hover:scale-105 transform duration-200 shadow-lg md:h-80"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
    >
      <div className="font-bold text-2xl text-gray-200">{calendar.title}</div>
      <div className="text-gray-200">{calendarBegin + ' - ' + calendarEnd}</div>
      <div className="text-gray-200">
        <span className="font-bold text-xl">{calendar.doorCount}</span> Türchen
      </div>
      <div className="flex justify-center items-center">
        <div className="mt-2 grid gap-2 md:gap-1 grid-cols-7">
          {calendar.doors.map((d) => {
            const today = isToday(d.dueDate);
            const opened = d.opened;
            return (
              <div className="flex justify-center items-center" key={d.id}>
                <div
                  className={
                    'flex justify-center items-center text-xs shadow-md font-light rounded-lg text-gray-800 w-6 h-6 ' +
                    (today ? 'font-extrabold bg-gray-300' : 'bg-gray-300') +
                    ' ' +
                    (opened ? 'opacity-25' : 'opacity-100')
                  }
                >
                  {moment(d.dueDate).format('D')}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarListItem;

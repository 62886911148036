import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useStoreActions } from '../hooks';
import LoginPrimaryButton from './LoginPrimaryButton';
import LoginSecondaryButton from './LoginSecondaryButton';

const Login: React.FC = () => {
  const { register, errors, handleSubmit } = useForm();
  const login = useStoreActions((actions) => actions.currentUser.login);
  const history = useHistory();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const onLogin = () => {
    setDisabled(true);
    fetch(process.env.REACT_APP_API_ENDPOINT + '/Auth/Login', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName: userName, password: password }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setDisabled(true);
        if (response.success) {
          login(response.data);
        } else {
          setError(response.message);
        }
      });
  };

  return (
    <div
      className="flex flex-shrink px-10 pt-6 pb-6 md:px-20 md:pt-16 md:pb-12 rounded-lg shadow-2xl"
      style={{
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }}
    >
      <div className="hidden">
        Erstelle deinen digitalen Countdown-Kalender für Freunde und Familie.
      </div>
      <form
        className="h-full flex flex-col justify-center items-center w-64 m-0"
        onSubmit={handleSubmit(onLogin)}
      >
        <div className="flex flex-col w-full mt-3">
          <label className="text-sm tracking-wider text-gray-800 font-semibold">
            E-Mail
          </label>
          <input
            ref={register({ required: true })}
            type="email"
            className="border bg-gray-100 rounded-md w-full p-2 px-3 outline-none text-gray-800 border-green-800 mt-1"
            name="username"
            onChange={(e) => setUserName(e.target.value)}
          />
          {errors.username && 'Bitte geben Sie eine E-Mail ein'}
        </div>
        <div className="flex flex-col mt-3 w-full">
          <label className="text-sm tracking-wider text-gray-800 font-semibold">
            Passwort
          </label>
          <input
            ref={register({ required: true })}
            className="border bg-gray-100 rounded-md w-full p-2 px-3 outline-none text-gray-800 mt-1 border-green-800"
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && 'Bitte geben Sie ein Passwort ein'}
          {/* <button
            type="button"
            className="text-secondary-700 hover:text-secondary-600 self-start text-sm"
          >
            Passwort vergessen?
          </button> */}
        </div>
        {error && (
          <div className="mt-5 p-3 w-full border-2 border-orange-400 rounded-lg text-sm">
            <span className="text-gray-800">{error}</span>
          </div>
        )}
        <LoginPrimaryButton disabled={disabled} caption="Log in" />
        <LoginSecondaryButton
          caption="Registrieren"
          onClickFunction={() => history.push('/Register')}
        />
      </form>
    </div>
  );
};

export default Login;

import { queryCache } from 'react-query';
import {
  AddCalendarPayload,
  Calendar,
  Content,
  ContentFile,
  ContentUploadPayload,
  Door,
  GetCountDto,
  ReorderPayload,
} from 'src/Store';

export const CheckAuth = (response: Response, callBack: CallableFunction) => {
  if (response.status === 401) {
    callBack();
    return;
  } else {
    return response.json();
  }
};

export const headers = () => {
  const token = localStorage.getItem('token');
  if (token) return new Headers([['Authorization', 'Bearer ' + token]]);
  return new Headers();
};

export const deleteContent = (id: number): Promise<Content[]> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Content/Delete/' + id, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.data);
      } else {
        console.log(response.message);
        return Promise.resolve(response.message);
      }
    });
};

export const deleteCalendar = (id: number): Promise<Calendar[]> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Calendar/' + id, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.data);
      } else {
        console.log(response.message);
        return Promise.resolve(response.message);
      }
    });
};

export const getContents = (id: number): Promise<Content[]> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Content/GetAll/' + id, {
    method: 'GET',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.data);
      } else {
        console.log(response.message);
        return Promise.resolve(response.message);
      }
    });
};

export const updateContent = (content: Content): Promise<Content[]> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Content/Update/', {
    method: 'PUT',
    mode: 'cors',
    headers: headers(),
    body: JSON.stringify(content),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.data);
      } else {
        console.log(response.message);
        return Promise.resolve(response.message);
      }
    });
};

export const createCheckoutSession = (id: string) => {
  const headersExtended = headers();
  headersExtended?.append('content-type', 'application/json');
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + '/Payments/CreateCheckoutSession',
    {
      method: 'POST',
      mode: 'cors',
      headers: headersExtended,
      body: JSON.stringify({ id: parseInt(id) }),
    },
  );
};

export const setImageUrl = (
  imageUrl: string,
  id: string,
): Promise<Calendar> => {
  const headersExtended = headers();
  headersExtended?.append('content-type', 'application/json');
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + '/Calendar/SetImageUrl/' + id,
    {
      method: 'PUT',
      mode: 'cors',
      headers: headersExtended,
      body: JSON.stringify({ id: parseInt(id), imageUrl: imageUrl }),
    },
  )
    .then((response) => {
      queryCache.invalidateQueries('getCalendar');
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return;
      } else {
        console.log(response.message);
        return Promise.resolve(response.message);
      }
    });
};

export const reorderContents = (
  reorderPayload: ReorderPayload,
): Promise<Content[]> => {
  const headersExtended = headers();
  headersExtended?.append('content-type', 'application/json');
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Content/Reorder', {
    method: 'POST',
    mode: 'cors',
    headers: headersExtended,
    body: JSON.stringify(reorderPayload),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.data);
      } else {
        console.log(response.message);
        return Promise.resolve(response.message);
      }
    });
};

export const getCalendars = (): Promise<Calendar[]> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Calendar/GetAll', {
    method: 'GET',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        console.log(response.message);
      }
    });
};

export const getReceivedCalendars = (): Promise<Calendar[]> => {
  return fetch(
    process.env.REACT_APP_API_ENDPOINT + '/Calendar/GetAllReceived',
    {
      method: 'GET',
      mode: 'cors',
      headers: headers(),
    },
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        console.log(response.message);
      }
    });
};

export const getCalendar = (
  key: string,
  { id }: { id: string | undefined },
): Promise<Calendar> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Calendar/' + id, {
    method: 'GET',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        console.log(response.message);
      }
    });
};

export const getDoors = (
  key: string,
  id: { id: string | undefined },
): Promise<Door[]> => {
  const uri =
    id?.id !== undefined
      ? process.env.REACT_APP_API_ENDPOINT + '/Door/GetAll/' + id.id
      : process.env.REACT_APP_API_ENDPOINT + '/Door/GetAll';
  return fetch(uri, {
    method: 'GET',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        console.log(response.message);
      }
    });
};

export const getCounts = (): Promise<GetCountDto> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Admin/GetCounts', {
    method: 'GET',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        console.log(response.message);
      }
    });
};

export const getDoor = (
  key: string,
  { id }: { id: string | undefined },
): Promise<Door> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Door/' + id, {
    method: 'GET',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        console.log(response.message);
      }
    });
};

export const markDoorAsOpen = (id: string | undefined): Promise<Door[]> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Door/MarkAsOpen/' + id, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        console.log(response.message);
      }
    });
};

export const getFiles = (
  key: string,
  { id }: { id: string | undefined },
): Promise<ContentFile[]> => {
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/File/GetAll/' + id, {
    method: 'GET',
    mode: 'cors',
    headers: headers(),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        console.log(response.message);
      }
    });
};

export const uploadFile = (files: any): Promise<ContentUploadPayload> => {
  const formData = new FormData();
  formData.append('files', files[0]);
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/File/Add', {
    method: 'POST',
    mode: 'cors',
    headers: headers(),
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.data);
      } else {
        console.log(response.message);
        return Promise.resolve(response.message);
      }
    });
};

export const addContent = (
  doorId: number,
  file: ContentUploadPayload,
): Promise<Content[]> => {
  const contentType = file?.contentType;
  const formContent = file?.formContent;
  const headersExtended = headers();
  headersExtended?.append('content-type', 'application/json');
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Content/Add', {
    method: 'POST',
    mode: 'cors',
    headers: headersExtended,
    body: JSON.stringify({
      text: file.uri != undefined ? file.uri : formContent,
      contentType: contentType,
      doorId: doorId,
      fileId: file.id != undefined ? file.id : null,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.data);
      } else {
        console.log(response.message);
        return Promise.resolve(response.message);
      }
    });
};

export const addCalendar = (
  calendar: AddCalendarPayload,
): Promise<Calendar | string> => {
  const headersExtended = headers();
  headersExtended?.append('content-type', 'application/json');
  return fetch(process.env.REACT_APP_API_ENDPOINT + '/Calendar/Add', {
    method: 'POST',
    mode: 'cors',
    headers: headersExtended,
    body: JSON.stringify(calendar),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.success) {
        queryCache.invalidateQueries('getCalendars');
        return Promise.resolve(response.data);
      } else {
        return Promise.resolve(response.message);
      }
    });
};

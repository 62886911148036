import { Action, action, Thunk, thunk } from 'easy-peasy';
import {
  addContent,
  deleteContent,
  getContents,
  reorderContents,
  updateContent,
  uploadFile,
} from './components/Api';

export interface GetCountDto {
  calendarCount: number;
  doorCount: number;
  contentCount: number;
  totalMbCount: number;
  userCount: number;
}

export interface Calendar {
  id: number;
  title: string;
  begin: Date;
  end: Date;
  createdAt: Date;
  updatedAt: Date;
  fileSizeLimit: number;
  token: string;
  createdById: number;
  doorCount: number;
  dueDate: Date;
  doors: Door[];
  imageUrl: string;
}

export interface ContentFile {
  fileName: string;
  fileExtension: string;
  uri: string;
  fileSize: number;
}

export interface Door {
  id: number;
  dueDate: Date;
  createdAt: Date;
  updatedAt: Date;
  opened: boolean;
  calendarId: number;
  contentCount: number;
}

export interface Content {
  id: number;
  text: string;
  contentType: string;
  createdAt: Date;
  updatedAt: Date;
  doorId: number;
  sortOrder: number;
  fileExtension: string;
  createdById: number;
}

export interface User {
  userName: string;
  token: string | null;
}

export interface ContentUploadPayload {
  id?: number;
  uri?: string;
  doorId: number;
  contentType: string;
  formContent: string;
}

export interface FileUploadPayload {
  id: number;
  files: any;
  contentType: string;
  formContent: string;
}

interface ContentForm {
  contentType: string;
  content: string;
  files: File[];
  changeType: Action<ContentForm, string>;
  setContent: Action<ContentForm, string>;
  setFiles: Action<ContentForm, File[]>;
}

export interface AddCalendarPayload {
  title: string;
  begin: Date;
  end: Date;
}

interface CalendarModel {
  items: Calendar[];
  addCalendar: Action<CalendarModel, Calendar>;
  deleteCalendar: Action<CalendarModel, Calendar[]>;
  updateCalendar: Action<CalendarModel, Calendar>;
  getCalendars: Action<CalendarModel, Calendar[]>;
}

interface DoorModel {
  items: Door[];
  getDoors: Action<DoorModel, Door[]>;
  updateDoor: Action<DoorModel, Door>;
}

export interface ReorderPayload {
  id: number;
  oldIndex: number;
  newIndex: number;
}

interface ContentModel {
  items: Content[];
  addContent: Action<ContentModel, Content>;
  deleteContent: Action<ContentModel, number>;
  updateContent: Action<ContentModel, Content>;
  reorderContent: Action<ContentModel, ReorderPayload>;
  pushContents: Action<ContentModel, Content[]>;
  deleteContentApi: Thunk<ContentModel, number, null, {}, Promise<Content[]>>;
  getContentsApi: Thunk<ContentModel, number, null, {}, Promise<Content[]>>;
  updateContentApi: Thunk<ContentModel, Content, null, {}, Promise<Content[]>>;
  reorderContentsApi: Thunk<
    ContentModel,
    ReorderPayload,
    null,
    {},
    Promise<Content[]>
  >;
  uploadContentApi: Thunk<
    ContentModel,
    ContentUploadPayload,
    null,
    {},
    Promise<void | Content[]>
  >;
  uploadFileApi: Thunk<
    ContentModel,
    FileUploadPayload,
    null,
    {},
    Promise<void | Content[]>
  >;
}

interface UserModel {
  user: User;
  updateUser: Action<UserModel, User>;
  login: Action<UserModel, string>;
  logout: Action<UserModel>;
}

interface StoreModel {
  calendars: CalendarModel;
  doors: DoorModel;
  contents: ContentModel;
  currentUser: UserModel;
  contentForm: ContentForm;
}

const contentForm: ContentForm = {
  files: [],
  content: '',
  contentType: '',
  changeType: action((state, payload) => {
    state.files = [];
    state.content = '';
    state.contentType = payload;
  }),
  setContent: action((state, payload) => {
    state.content = payload;
  }),
  setFiles: action((state, payload) => {
    state.files = payload;
  }),
};

const calendarModel: CalendarModel = {
  items: [],
  addCalendar: action((state, payload) => {
    state.items.push(payload);
  }),
  deleteCalendar: action((state, payload) => {
    state.items = payload;
  }),
  getCalendars: action((state, payload) => {
    state.items = payload;
  }),
  updateCalendar: action((state, payload) => {
    const items = state.items.filter((item) => item.id != payload.id);
    items.push(payload);
    state.items = items;
  }),
};
const doorModel: DoorModel = {
  items: [],
  getDoors: action((state, payload) => {
    state.items = payload;
  }),
  updateDoor: action((state, payload) => {
    const items = state.items.filter((item) => item.id != payload.id);
    items.push(payload);
    state.items = items;
  }),
};
const contentModel: ContentModel = {
  items: [],
  addContent: action((state, payload) => {
    state.items.push(payload);
  }),
  deleteContent: action((state, payload) => {
    const items = state.items.filter((item) => item.id != payload);
    state.items = items;
  }),
  updateContent: action((state, payload) => {
    const items = state.items.filter((item) => item.id != payload.id);
    items.push(payload);
    state.items = items;
  }),
  reorderContent: action((state, payload) => {
    const ordered = state.items.sort((a, b) => a.sortOrder - b.sortOrder);
    const [removed] = ordered.splice(payload.oldIndex, 1);
    ordered.splice(payload.newIndex, 0, removed);
    ordered.forEach((e, i) => {
      e.sortOrder = i;
    });
    state.items = ordered;
  }),
  pushContents: action((state, payload) => {
    state.items = payload;
  }),
  deleteContentApi: thunk((actions, payload) => {
    return deleteContent(payload);
  }),
  getContentsApi: thunk((actions, payload) => {
    return getContents(payload);
  }),
  updateContentApi: thunk((actions, payload) => {
    return updateContent(payload);
  }),
  reorderContentsApi: thunk((actions, payload) => {
    actions.reorderContent(payload);
    return reorderContents(payload);
  }),
  uploadFileApi: thunk((actions, payload) => {
    return uploadFile(payload.files).then((res) =>
      addContent(payload.id, {
        doorId: res.doorId,
        contentType: payload.contentType,
        formContent: payload.formContent,
        id: res.id,
        uri: res.uri,
      }).then((res) => {
        actions.pushContents(res);
      }),
    );
  }),
  uploadContentApi: thunk((actions, payload) => {
    return addContent(payload.doorId, { ...payload }).then((res) =>
      actions.pushContents(res),
    );
  }),
};
const currentUserModel: UserModel = {
  user: {
    userName: '',
    token:
      localStorage.getItem('token') !== null
        ? localStorage.getItem('token')
        : '',
  },
  updateUser: action((state, payload) => {
    state.user = payload;
  }),
  login: action((state, payload) => {
    state.user.token = payload;
    localStorage.setItem('token', payload);
  }),
  logout: action((state) => {
    state.user.token = '';
    state.user.userName = '';
    localStorage.removeItem('token');
  }),
};

const StoreModel: StoreModel = {
  calendars: calendarModel,
  doors: doorModel,
  contents: contentModel,
  currentUser: currentUserModel,
  contentForm: contentForm,
};

export default StoreModel;

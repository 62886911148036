import TokenDecode from 'jwt-decode';
import { useMemo } from 'react';

export const useUserInfo = () => {
  const token = localStorage.getItem('token');
  if (!token || token === '') {
    return {
      info: '',
      isGuest: true,
    };
  }
  const decodedToken = useMemo(() => TokenDecode(token), [token]);
  return {
    info: decodedToken,
    isGuest: decodedToken.role === 'ViewCalendar',
    isAdmin: decodedToken.role.includes('Admin'),
  };
};

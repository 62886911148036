import React from 'react';

interface DeleteContentButtonProps {
  cb?: CallableFunction;
  inverted?: boolean;
}

const IconButton = (
  props: React.PropsWithChildren<DeleteContentButtonProps>,
) => {
  return (
    <div
      className="w-8 cursor-pointer"
      onClick={() => {
        if (props.cb) props.cb();
      }}
    >
      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        className={
          'fill-current opacity-50 rounded-full md:hover:scale-110 ease-in-out duration-200 transform ' +
          (props.inverted ? 'text-green-800' : 'text-gray-200 bg-transparent')
        }
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default IconButton;

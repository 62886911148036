import React from 'react';
import { useMonth } from '@datepicker-react/hooks';
import Day from './Day';

function Month({ year, month, firstDayOfWeek, i }) {
  const { days, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });
  return (
    <div className={i === 1 ? 'hidden md:block' : ''}>
      <div className="text-center my-3">
        <strong className=" text-gray-800">{monthLabel}</strong>
      </div>
      <div
        className={`grid grid-cols-7 gap-0 justify-center h-10 items-center mb-3 border-b border-gray-300`}
      >
        {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map((dayLabel) => (
          <div className="text-center" key={dayLabel}>
            <span className="font-light">{dayLabel}</span>
          </div>
        ))}
      </div>
      <div className={`grid grid-cols-7 gap-px justify-center`}>
        {days.map((day, i) => (
          <Day date={day.date} key={i} day={day.dayLabel} />
        ))}
      </div>
    </div>
  );
}

export default Month;

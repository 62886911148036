import React from 'react';
import { getCounts } from './Api';
import DashboardTile from './DashboardTile';
import { useQuery } from 'react-query';
import LoadingIndicator from './LoadingIndicator';
import { getFileSizeInMb } from './utils';

const AdminDashboard = () => {
  const getCountsQuery = useQuery(['getCounts'], getCounts);
  const counts = getCountsQuery.data;
  const countsLoading = getCountsQuery.isLoading;
  const fileSizeInMb = getFileSizeInMb(counts?.totalMbCount ?? 0);
  return (
    <div className="grid grid-cols-2 l:grid-cols-4 gap-4 max-w-screen-l m-auto">
      <DashboardTile caption="Kalender erstellt" type="text" disabled={false}>
        {countsLoading ? <LoadingIndicator /> : counts?.calendarCount}
      </DashboardTile>
      <DashboardTile caption="Türchen erstellt" type="text" disabled={false}>
        {countsLoading ? <LoadingIndicator /> : counts?.doorCount}
      </DashboardTile>
      <DashboardTile caption="Inhalte erstellt" type="text" disabled={false}>
        {countsLoading ? <LoadingIndicator /> : counts?.contentCount}
      </DashboardTile>
      <DashboardTile caption="User registriert" type="text" disabled={false}>
        {countsLoading ? <LoadingIndicator /> : counts?.userCount}
      </DashboardTile>
      <DashboardTile caption="MB benötigt" type="text" disabled={false}>
        {countsLoading ? <LoadingIndicator /> : fileSizeInMb}
      </DashboardTile>
    </div>
  );
};

export default AdminDashboard;

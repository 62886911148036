import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { createCheckoutSession } from './Api';
import PrimaryButton from './PrimaryButton';

const stripePromise = loadStripe(process.env.PUBLISHABLE_KEY ?? '');

const Checkout: React.FC<{ id: string }> = ({ id }) => {
  const [disabled, setDisabled] = useState(false);
  const handleClick = async () => {
    setDisabled(true);
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await createCheckoutSession(id);

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe?.redirectToCheckout({
      sessionId: session.id,
    });

    setDisabled(false);

    if (result?.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return (
    <PrimaryButton
      disabled={disabled}
      caption={'Für 4,00€ kaufen'}
      onClickFunction={handleClick}
    />
  );
};

export default Checkout;

import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useUserInfo } from '../hooks/hook.useUserInfo';
import CreatedCalendars from './CreatedCalendars';
import Dashboard from './Dashboard';
import Doors from './Calendar';
import ViewCalendar from './ViewCalendar';
import TokenLogin from './TokenLogin';
import ReceivedCalendars from './ReceivedCalendars';
import { AnimatePresence, motion } from 'framer-motion';
import AddCalendar from './AddCalendar';
import AdminDashboard from './AdminDashboard';

const MainSwitch = () => {
  const location = useLocation();
  const userInfo = useUserInfo();
  return (
    <div className="w-full">
      <div className="pt-0 l:pt-5 p-5 max-w-screen-xl m-auto">
        {!userInfo.isGuest ? (
          <AnimatePresence exitBeforeEnter>
            <Switch key={location.pathname}>
              <Route path={`/addCalendar`}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="addCalendar"
                >
                  <AddCalendar />
                </motion.div>
              </Route>
              {userInfo.isAdmin && (
                <Route path={`/adminDashboard`}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key="adminDashboard"
                  >
                    <AdminDashboard />
                  </motion.div>
                </Route>
              )}
              <Route path={`/createdCalendars/addCalendar`}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="createdCalendarsAddCalendar"
                >
                  <AddCalendar />
                </motion.div>
              </Route>
              <Route path="/createdCalendars">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="createdCalendar"
                >
                  <CreatedCalendars />
                </motion.div>
              </Route>
              <Route path="/receivedCalendars">
                <div>
                  <ReceivedCalendars />
                </div>
              </Route>
              <Route path="/calendar/:calendarId">
                <div>
                  <Doors />
                </div>
              </Route>
              <Route path="/viewCalendar/:calendarId">
                <div>
                  <ViewCalendar />
                </div>
              </Route>
              <Route path="/calendaropen/:calendarId">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="tokenLogin"
                >
                  <TokenLogin />
                </motion.div>
              </Route>
              <Route path="/">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="dashboard"
                >
                  <Dashboard />
                </motion.div>
              </Route>
            </Switch>
          </AnimatePresence>
        ) : (
          <AnimatePresence exitBeforeEnter>
            <Switch key={location.pathname}>
              <Route path="/viewCalendar/:calendarId">
                <div>
                  <ViewCalendar />
                </div>
              </Route>
              <Route path="/*">
                <motion.div
                  className="flex justify-center items-center h-full"
                  key="tokenLoginAnon"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className=" text-xl text-gray-800">
                    Auf diesen Bereich haben Sie keinen Zugriff.
                  </div>
                </motion.div>
              </Route>
            </Switch>
          </AnimatePresence>
        )}
      </div>
    </div>
  );
};

export default MainSwitch;

import React, { useState } from 'react';
import { useDatepicker, START_DATE } from '@datepicker-react/hooks';
import Month from './Month';
import DatepickerContext from './DatepickerContext';

function Datepicker(props: {
  handleChange: CallableFunction;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  month: number;
}) {
  const [state, setState] = useState({
    startDate: props.startDate || null,
    endDate: props.endDate || null,
    focusedInput: START_DATE,
  });
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths: props.month,
  });

  function handleDateChange(data) {
    props.handleChange(data);
    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE });
    } else {
      setState(data);
    }
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <div className="flex w-full justify-center">
        <div
          className={`relative grid grid-cols-1 md:grid-cols-${activeMonths.length} gap-2 md:gap-5 bg-gray-100 max-w-2xl md:px-3`}
        >
          <div className="absolute top-0 w-full flex justify-between md:px-5">
            <button
              type="button"
              className="focus:outline-none group"
              onClick={goToPreviousMonths}
            >
              <svg
                className="h-10 text-gray-800 md:group-hover:-translate-x-2 transform duration-200"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"></path>
              </svg>
            </button>
            <button
              className="focus:outline-none group"
              type="button"
              onClick={goToNextMonths}
            >
              <svg
                className="h-10 text-gray-800 group-hover:translate-x-2 transform duration-200"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </button>
          </div>
          {activeMonths.map((month, i) => (
            <Month
              key={`${month.year}-${month.month}`}
              year={month.year}
              month={month.month}
              firstDayOfWeek={firstDayOfWeek}
              i={i}
            />
          ))}
        </div>
      </div>
    </DatepickerContext.Provider>
  );
}

export default Datepicker;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { AddCalendarPayload } from '../Store';
import Datepicker from './datepicker/Datepicker';
import { addCalendar } from './Api';
import PrimaryButton from './PrimaryButton';

const AddCalendar = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm<
    AddCalendarPayload
  >();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [error, setError] = useState<string>('');
  const handleChange = (data) => {
    setStartDate(data.startDate);
    setEndDate(data.endDate);
  };

  return (
    <div
      className={
        'z-10 h-full w-full fixed top-0 left-0 bg-gray-300 flex justify-center items-center py-5 px-5 s:py-20 s:px-20 l:py-48 l:px-48 overflow-auto max-w '
      }
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      onClick={() => {
        history.goBack();
      }}
    >
      <div
        className="flex flex-col flex-grow bg-gray-100 h-full rounded-lg max-w-screen-l p-2 md:p-6 overflow-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <form
          onSubmit={handleSubmit((data) =>
            addCalendar({
              begin: new Date(data.begin),
              end: new Date(data.end),
              title: data.title,
            }).then((response) => {
              if (typeof response !== 'string') {
                setStartDate(undefined);
                setEndDate(undefined);
                reset();
                history.push('/calendar/' + response.id);
              } else {
                setError(response as string);
              }
            }),
          )}
        >
          <input
            type="hidden"
            value={moment(startDate)?.toString() || ''}
            name="begin"
            ref={register({ required: true })}
          />
          <input
            type="hidden"
            value={moment(endDate)?.toString() || ''}
            name="end"
            ref={register({ required: true })}
          />
          <div className="md:block hidden">
            <Datepicker handleChange={handleChange} month={2} />
          </div>
          <div className="md:hidden block">
            <Datepicker handleChange={handleChange} month={1} />
          </div>

          {error !== '' && (
            <div className="text-red-800 text-lg w-full text-center p-5">
              {error}
            </div>
          )}
          {startDate && endDate && (
            <>
              <div className="flex flex-col items-start my-3 max-w-xs mx-auto">
                <label className="text-sm tracking-wide font-semibold text-gray-800">
                  Kalendername
                </label>
                <input
                  name={'title'}
                  ref={register({ required: true })}
                  className="w-full rounded border border-gray-400 p-2"
                />
                {errors.title && (
                  <p className="text-base font-light">
                    {'Bitte gib dem Kalender einen Namen'}
                  </p>
                )}
              </div>

              <div className="flex flex-row justify-center items-center">
                <div className="w-full max-w-xs">
                  <PrimaryButton caption="Kalender erstellen" />
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddCalendar;

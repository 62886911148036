import React from 'react';
import Wallpaper1 from '../res/wallpaper1.jpg';
import Wallpaper1Small from '../res/wallpaper1_small.jpg';
import Wallpaper2 from '../res/wallpaper2.jpg';
import Wallpaper2Small from '../res/wallpaper2_small.jpg';
import Wallpaper3 from '../res/wallpaper3.jpg';
import Wallpaper3Small from '../res/wallpaper3_small.jpg';
import Wallpaper4 from '../res/wallpaper4.jpg';
import Wallpaper4Small from '../res/wallpaper4_small.jpg';
import Wallpaper5 from '../res/wallpaper5.jpg';
import Wallpaper5Small from '../res/wallpaper5_small.jpg';
import Wallpaper6 from '../res/wallpaper6.jpg';
import Wallpaper6Small from '../res/wallpaper6_small.jpg';
import Confetti from '../res/confetti.jpg';
import ConfettiSmall from '../res/confetti-small.jpg';
import Balloon from '../res/balloon.jpg';
import BallonSmall from '../res/balloon-small.jpg';
import Flower from '../res/flower.jpg';
import FlowerSmall from '../res/flower-small.jpg';
import CalendooBackground from '../res/calendoo-background.jpg';
import CalendooBackgroundSmall from '../res/calendoo-background-small.jpg';
import CalendooBackgroundDark from '../res/calendoo-background-dark.jpg';
import CalendooBackgroundDarkSmall from '../res/calendoo-background-dark-small.jpg';
import CalendooBackgroundLight from '../res/calendoo-background-light.jpg';
import CalendooBackgroundLightSmall from '../res/calendoo-background-light-small.jpg';
import { setImageUrl } from './Api';

const ImageListEntry: React.FC<{
  smallImageUrl: string;
}> = ({ smallImageUrl }) => {
  return (
    <>
      <div className="relative p-1 md:p-3 flex w-full h-full items-center justify-center">
        <div
          className="bg-cover bg-center bg-no-repeat w-full h-24 rounded-lg shadow-md
             cursor-pointer transform md:hover:scale-105 md:hover:shadow-lg duration-200"
          style={{ backgroundImage: `url(${smallImageUrl})` }}
        ></div>
      </div>
    </>
  );
};

const ImageSelectList: React.FC<{ id: string | undefined }> = ({ id }) => {
  const entries = [
    { imageUrl: Wallpaper1, smallImageUrl: Wallpaper1Small },
    { imageUrl: Wallpaper2, smallImageUrl: Wallpaper2Small },
    { imageUrl: Wallpaper3, smallImageUrl: Wallpaper3Small },
    { imageUrl: Wallpaper4, smallImageUrl: Wallpaper4Small },
    { imageUrl: Wallpaper5, smallImageUrl: Wallpaper5Small },
    { imageUrl: Wallpaper6, smallImageUrl: Wallpaper6Small },
    { imageUrl: Confetti, smallImageUrl: ConfettiSmall },
    { imageUrl: Flower, smallImageUrl: FlowerSmall },
    { imageUrl: Balloon, smallImageUrl: BallonSmall },
    { imageUrl: CalendooBackground, smallImageUrl: CalendooBackgroundSmall },
    {
      imageUrl: CalendooBackgroundLight,
      smallImageUrl: CalendooBackgroundLightSmall,
    },
    {
      imageUrl: CalendooBackgroundDark,
      smallImageUrl: CalendooBackgroundDarkSmall,
    },
  ];
  return (
    <fieldset>
      <legend className="sr-only">Privacy setting</legend>

      <div className="bg-gray-300 rounded-md grid grid-cols-2 md:grid-cols-3 p-1 md:p-3">
        {entries.map((e, i) => {
          return (
            <div
              className="w-full h-full"
              key={i}
              onClick={() => {
                setImageUrl(e.imageUrl, id ?? ''); // Todo: Invalidate Query.
              }}
            >
              <ImageListEntry {...e} />
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

export default ImageSelectList;

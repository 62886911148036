import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import './index.css';
import { createStore } from 'easy-peasy';
import { StoreProvider } from 'easy-peasy';
import Store from './Store';
import { useStoreState } from './hooks';
import Application from './components/Application';
import moment from 'moment';
import AuthApp from './components/AuthApp';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from './components/AppContext';

moment.locale('de');
Date.prototype.toJSON = function () {
  return moment(this).format();
};

const store = createStore(Store);

const App = () => {
  const token: string | null = useStoreState(
    (state) => state.currentUser.user.token,
  );
  if (token == '') {
    return (
      <Router>
        <AuthApp />
      </Router>
    );
  } else {
    return <Application />;
  }
};

ReactDom.render(
  <StoreProvider store={store}>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </StoreProvider>,
  document.getElementById('root') as HTMLElement,
);

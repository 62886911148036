import React from 'react';

interface PrimaryButtonProps {
  caption: string;
  onClickFunction?: CallableFunction;
  disabled?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  onClickFunction,
  caption,
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      className={
        `outline-none focus:outline-none md:hover:scale-105 l:hover:scale-110 transform duration-200 bg-green-800 
      text-green-100 py-1 px-2 md:py-3 md:px-4 w-full text-sm md:text-base tracking-wide font-semibold md:font-bold rounded-md ` +
        (disabled ? 'opacity-50' : '')
      }
      onClick={(e) => (onClickFunction != undefined ? onClickFunction(e) : '')}
      type="submit"
    >
      <span className="">{caption}</span>
    </button>
  );
};

export default PrimaryButton;

import React from 'react';
import { useStoreActions } from '../hooks';

interface ContentIconProps {
  icon: 'text' | 'file' | 'link';
}

const getIconPathByString = (icon: string) => {
  switch (icon) {
    case 'text':
      return <path d="M12 5h-2v12H8V3h8v2h-2v12h-2V5zM8 3a4 4 0 1 0 0 8V3z" />;
    case 'picture':
      return (
        <path d="M0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm11 9l-3-3-6 6h16l-5-5-2 2zm4-4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
      );
    case 'video':
      return (
        <path d="M16 7l4-4v14l-4-4v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v3zm-8 7a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      );
    case 'mic':
      return (
        <path d="M9 18v-1.06A8 8 0 0 1 2 9h2a6 6 0 1 0 12 0h2a8 8 0 0 1-7 7.94V18h3v2H6v-2h3zM6 4a4 4 0 1 1 8 0v5a4 4 0 1 1-8 0V4z" />
      );
    case 'link':
      return (
        <path d="M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z" />
      );
    case 'file':
      return (
        <>
          <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
          <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
        </>
      );
  }
};

const getTextForIcon = (icon: string) => {
  switch (icon) {
    case 'text':
      return 'Text';
    case 'link':
      return 'Link';
    case 'file':
      return 'Datei';
  }
};

const ContentIcon = (props: React.PropsWithChildren<ContentIconProps>) => {
  const changeContentType = useStoreActions(
    (actions) => actions.contentForm.changeType,
  );
  return (
    <div className="flex flex-col justify-center items-center">
      <div
        className="rounded-full h-12 w-12 md:h-16 md:w-16 p-3 border border-gray-800 text-gray-800
      md:p-4 cursor-pointer md:hover:scale-110 transform duration-200 ease-in-out"
        onClick={() => changeContentType(props.icon)}
      >
        <svg
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          {getIconPathByString(props.icon)}
        </svg>
      </div>
      <div className="text-lg mt-1 font-bold text-gray-800 text-center">
        {getTextForIcon(props.icon)}
      </div>
    </div>
  );
};

export default ContentIcon;

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useStoreActions } from '../hooks';
import { headers } from './Api';

const TokenLogin = () => {
  const calendarToken = useLocation().search.split('calendarToken')[1].slice(1);
  const login = useStoreActions((actions) => actions.currentUser.login);
  const [, setError] = useState(null);
  const history = useHistory();
  const { calendarId }: { calendarId?: string | undefined } = useParams();

  useEffect(() => {
    const headersExtended = headers();
    headersExtended?.append('content-type', 'application/json');
    fetch(process.env.REACT_APP_API_ENDPOINT + '/Auth/TokenLogin', {
      method: 'POST',
      mode: 'cors',
      headers: headersExtended,
      body: JSON.stringify({
        calendarToken: calendarToken,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.success) {
          //history.push('/viewCalendar/' + calendarId);
          login(response.data);
        } else {
          setError(response.message);
        }
      });
  }, []);
  return <></>;
};

export default TokenLogin;

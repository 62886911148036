import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useStoreActions } from '../hooks';
import { useDoors } from '../hooks/hook.useDoors';
import { useUserInfo } from '../hooks/hook.useUserInfo';
import background from '../res/wallpaper1.jpg';
import { getCalendar } from './Api';
import { AppContext } from './AppContext';
import Door from './Door';
import ViewContents from './ViewContents';

const ViewCalendar = () => {
  const userInfo = useUserInfo();
  const { calendarId }: { calendarId?: string | undefined } = useParams();

  const getCalendarQuery = useQuery(
    ['getCalendar', { id: calendarId }],
    getCalendar,
  );
  const { doorsQuery } = useDoors(calendarId);

  const addDoorsToStore = useStoreActions((actions) => actions.doors.getDoors);

  const { path, url } = useRouteMatch();
  const { setImageUrl } = useContext(AppContext);

  const currentCalendar = getCalendarQuery.data;

  useEffect(() => {
    setImageUrl(currentCalendar?.imageUrl ?? background);
    return () => {
      setImageUrl(background);
    };
  }, [currentCalendar]);

  useEffect(() => {
    addDoorsToStore(doorsQuery.data ?? []);
  }, [doorsQuery.data]);
  const isReceived =
    userInfo.info.nameid !== currentCalendar?.createdById &&
    currentCalendar?.createdById !== undefined;

  const doors = doorsQuery.data ?? [];
  return (
    <>
      <div className="max-w-screen-l m-auto">
        <div className="text-center">
          <h1
            className="mb-5 text-3xl md:text-5xl font-extrabold text-gray-300"
            style={{ textShadow: 'rgba(0, 0, 0, 0.5) 0 0 100px' }}
          >
            {currentCalendar?.title}
          </h1>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-3 l:grid-cols-4 gap-4 select-none relative">
          {doors.map((d, i) => {
            return (
              <Link to={`${url}/${d.id}`} key={i}>
                <Door door={d} key={i} isReceived={isReceived} />
              </Link>
            );
          })}
        </div>
      </div>
      <Switch>
        <Route path={`${path}/:doorId`}>
          <ViewContents />
        </Route>
      </Switch>
    </>
  );
};

export default ViewCalendar;

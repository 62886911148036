import React from 'react';
import { useCalendars } from '../hooks/hook.useCalendars';
import Calendars from './Calendars';

const CreatedCalendars = () => {
  const { calendars } = useCalendars();
  return <Calendars calendars={calendars} doorLink="calendar" />;
};

export default CreatedCalendars;

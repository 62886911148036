import React from 'react';

interface LoginSecondaryButtonProps {
  caption: string;
  onClickFunction: CallableFunction;
}

const SecondaryButton: React.FC<LoginSecondaryButtonProps> = ({
  onClickFunction,
  caption,
}) => {
  return (
    <button
      type="button"
      className="outline-none focus:outline-none md:hover:scale-105 l:hover:scale-110 transform duration-200 text-green-800 
       py-3 px-4 w-full text-base tracking-wide font-bold rounded-md"
      onClick={() => onClickFunction()}
    >
      {caption}
    </button>
  );
};

export default SecondaryButton;

import React from 'react';

interface DashboardTextProps {
  caption: string;
}

const DashboardText: React.FC<DashboardTextProps> = ({ children, caption }) => {
  return (
    <>
      <div className="text-gray-800 text-3xl md:text-5xl font-semibold leading-none font-mono">
        {children}
      </div>
      <span className="text-gray-800 mt-3 text-base">&nbsp;{caption}</span>
    </>
  );
};

export default DashboardText;

import React, { FC } from 'react';

const Link: FC<{ link: string; caption: string }> = ({ link, caption }) => {
  if (link.includes('youtube.com')) {
    const splittedLink = link.split('&')[0].split('=');
    return (
      <iframe
        className="rounded-lg border border-gray-400"
        width="100%"
        height="315"
        src={
          'https://www.youtube.com/embed/' +
          splittedLink[splittedLink.length - 1]
        }
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }
  if (link.includes('youtu.be')) {
    return (
      <iframe
        className="rounded-lg border border-gray-400"
        width="100%"
        height="315"
        src={
          'https://www.youtube.com/embed/' +
          link.split('/')[link.split('/').length - 1]
        }
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }
  if (link.includes('spotify')) {
    const splittedLink = link.split('/');
    const type = splittedLink[splittedLink.length - 2];
    const spotifyId = splittedLink[splittedLink.length - 1].split('?')[0];
    const src = `https://open.spotify.com/embed/${type}/${spotifyId}`;

    return (
      <iframe
        src={src}
        className="rounded-lg border border-gray-400"
        width="100%"
        height="380"
        frameBorder="0"
        allowTransparency={true}
        allow="encrypted-media"
      ></iframe>
    );
  }
  return (
    <div
      className={
        'w-full bg-white flex justify-center rounded-lg border border-gray-300 p-3'
      }
    >
      <div className="flex items-center">
        <svg
          className={'fill-current h-4 mr-3 text-gray-500'}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
            clipRule="evenodd"
          />{' '}
        </svg>
        <a rel="noopener noreferrer" target="_blank" href={link}>
          {caption}
        </a>
      </div>
    </div>
  );
};

export default Link;

import React from 'react';
import { Door } from '../Store';
import moment from 'moment';
import { useUserInfo } from '../hooks/hook.useUserInfo';
import { isToday } from './utils';

interface DoorProps {
  door: Door;
  isReceived?: boolean;
}

const DoorItem = (props: React.PropsWithChildren<DoorProps>) => {
  const date = new Date(props.door.dueDate);
  const userInfo = useUserInfo();
  const today = isToday(date);
  const opened = props.door.opened;
  const doorOpenedInCreateMode =
    opened && !userInfo.isGuest && !props.isReceived;
  return (
    <>
      <div
        className={
          'relative p-3 flex justify-center items-center rounded-lg cursor-pointer ' +
          'origin-top-left md:hover:-skew-y-3 md:hover:shadow-2xl md:hover:scale-x-90 transform duration-200 shadow-lg h-24 s:h-40 md:h-48 l:h-56 ' +
          (today
            ? 'text-gray-700 bg-gradient-to-br from-gray-300 to-gray-400'
            : 'text-gray-700 bg-gray-300') +
          ' ' +
          (doorOpenedInCreateMode && 'opacity-75')
        }
      >
        <div
          className={`font-normal l:font-normal text-gray-700 text-3xl s:text-5xl md:text-6xl tracking-wider ${
            today ? 'l:font-semibold' : 'l:font-normal'
          }`}
        >
          {moment(date).format('D')}
        </div>
        {/*Check Icon*/}
        {doorOpenedInCreateMode && (
          <div className="absolute right-0 bottom-0">
            <div className="p-1">
              <svg
                className="w-4 h-4 md:w-8 md:h-8 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        )}
        {!userInfo.isGuest && props.door.contentCount > 0 && !props.isReceived && (
          <div className="absolute left-0 bottom-0">
            <div className="p-2">
              <div
                className={`rounded-md md:rounded-lg h-4 w-4 md:h-8 md:w-8 md:font-semibold leading-none text-xs md:text-base border
                 bg-gray-400 text-gray-700 flex justify-center items-center bg-transparent`}
              >
                {props.door.contentCount}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DoorItem;

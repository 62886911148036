import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { useQuery } from 'react-query';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  // eslint-disable-next-line prettier/prettier
  useRouteMatch
} from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { useStoreActions } from '../hooks';
import { useDoors } from '../hooks/hook.useDoors';
import { useQueryParam } from '../hooks/hook.useQueryParam';
import background from '../res/wallpaper1.jpg';
import { deleteCalendar, getCalendar, getFiles } from './Api';
import { AppContext } from './AppContext';
import CalendooModal from './CalendooModal';
import Checkout from './Checkout';
import Contents from './Contents';
import Door from './Door';
import ImageSelectList from './ImageSelectList';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { getFileSizeInMb } from './utils';

type Status = 'success' | 'error';

const Doors = () => {
  const history = useHistory();
  const { calendarId } = useParams<{
    calendarId?: string;
  }>();
  const status = useQueryParam('status');
  const getCalendarQuery = useQuery(
    ['getCalendar', { id: calendarId }],
    getCalendar,
  );
  const { doorsQuery } = useDoors(calendarId);
  const getFilesQuery = useQuery(['getFiles', { id: calendarId }], getFiles);

  const { setImageUrl } = useContext(AppContext);

  const calendarIsLoading = getCalendarQuery.isLoading;
  const currentCalendar = getCalendarQuery.data;

  useEffect(() => {
    setImageUrl(currentCalendar?.imageUrl ?? background);
    return () => {
      setImageUrl(background);
    };
  }, [currentCalendar]);

  const addDoorsToStore = useStoreActions((actions) => actions.doors.getDoors);

  const { url } = useRouteMatch();

  useEffect(() => {
    addDoorsToStore(doorsQuery.data ?? []);
  }, [doorsQuery.data]);

  const doors = doorsQuery.data ?? [];
  const files = getFilesQuery.data ?? [];
  const fileSizeSum = files
    ?.map((f) => f?.fileSize ?? 0)
    .reduce((d, cur) => {
      return d + cur;
    }, 0);
  const fileSizeInMb = getFileSizeInMb(fileSizeSum);

  const [styleOpen, setStyleOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [copied, setIsCopied] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const paymentSucceeded = status === 'success';
    if (paymentSucceeded) {
      setShowConfetti(true);
      const timeout = setTimeout(() => {
        setShowConfetti(false);
      }, 7000);
      return () => clearTimeout(timeout);
    }
  }, []);

  const calendarLinkInputRef = useRef<HTMLInputElement>(null);
  const payedCalendar =
    getFileSizeInMb(currentCalendar?.fileSizeLimit ?? 0) > 100;
  const { width, height } = useWindowSize();
  return (
    <>
      <Switch>
        <Route path={`/calendar/:calendarId/:doorId`}>
          <Contents />
        </Route>
      </Switch>
      {!calendarIsLoading && (
        <div className="max-w-screen-l m-auto">
          <div className="w-full flex text-center items-center">
            <div className="select-none flex justify-start items-center">
              {/* Pen Icon */}
              <div
                className="transform duration-200 group border-2 border-gray-300 text-gray-300 hover:text-gray-800 
            rounded-full hover:bg-gray-300 p-2 cursor-pointer select-none"
                onClick={() => setStyleOpen((open) => !open)}
              >
                <svg
                  className="w-4 h-4 md:w-6 md:h-6 fill-current"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                </svg>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center w-full">
              <h1
                className="text-3xl md:text-5xl font-extrabold text-gray-300 flex-1 leading-none mb-2"
                style={{ textShadow: 'rgba(0, 0, 0, 0.5) 0 0 100px' }}
              >
                {currentCalendar?.title}
              </h1>
              <div className="flex items-center justify-center mb-3">
                <div className="flex flex-col items-end">
                  <div className="leading-none text-gray-300 font-semibold">
                    <div
                      className="flex items-center leading-none text-xs md:text-base tracking-wider"
                      style={{ textShadow: 'rgba(0, 0, 0, 0.5) 0 0 30px' }}
                    >
                      {fileSizeInMb}/
                      {(currentCalendar?.fileSizeLimit ?? 0) / 1000000} mb
                    </div>
                  </div>
                </div>
              </div>
              {!!currentCalendar?.dueDate && (
                <div>
                  <p
                    className="text-gray-300 font font-semibold text-base"
                    style={{ textShadow: 'rgba(0, 0, 0, 0.5) 0 0 30px' }}
                  >
                    Kalender läuft ab am:{' '}
                    {moment(currentCalendar?.dueDate).format('DD.MM.YYYY')}
                  </p>
                </div>
              )}
            </div>
            {/* Trash Icon */}
            <div
              className="transform duration-200 group border-2 border-gray-300 text-gray-300 hover:text-gray-800 
            rounded-full hover:bg-gray-300 p-2 cursor-pointer select-none"
              onClick={() => setConfirmIsOpen(true)}
            >
              <svg
                className="w-4 h-4 md:w-6 md:h-6 fill-current"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          <AnimatePresence>
            {styleOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-gray-300 rounded-lg shadow-lg"
              >
                <ImageSelectList id={calendarId} />
              </motion.div>
            )}
          </AnimatePresence>

          <div className="grid grid-cols-3 md:grid-cols-3 l:grid-cols-4 gap-4 select-none relative mt-4">
            {doors.map((d, i) => {
              return (
                <Link to={`${url}/${d.id}`} key={i}>
                  <Door door={d} key={i} />
                </Link>
              );
            })}

            <CalendooModal
              isOpen={confirmIsOpen}
              onRequestClose={() => {
                setConfirmIsOpen(false);
              }}
              title="Wirklich löschen?"
            >
              <div className="p-5 md:w-128 flex">
                <PrimaryButton
                  caption="Löschen"
                  onClickFunction={() => {
                    deleteCalendar(parseInt(calendarId ?? '')).then(() => {
                      history.push('/createdCalendars');
                    });
                  }}
                />
                <SecondaryButton
                  caption="Abbrechen"
                  onClickFunction={() => setConfirmIsOpen(false)}
                />
              </div>
            </CalendooModal>
          </div>
          {/* <div className="w-full h-px bg-gray-300 mt-8 mb-8 md:mt-12 md:mb-4"></div> */}
          <div className="grid gap-4 grid-cols-2 mt-12">
            {!payedCalendar && (
              <div className="rounded-lg bg-gray-300 p-5 opacity-100 flex-col flex justify-center items-center">
                <div className="text-sm md:text-base text-center text-gray-800 mb-3">
                  1GB Speicher und 1 Jahr Verfügbarkeit? <br />
                  <span className="font-semibold">
                    Jetzt upgraden für 4,00€
                  </span>
                </div>
                <div>{calendarId && <Checkout id={calendarId ?? ''} />}</div>
              </div>
            )}
            <div
              className="rounded-lg group bg-gray-300 p-5 opacity-100 flex flex-col justify-center 
            items-center cursor-pointer transform duration-200 ease-in-out md:hover:scale-105"
              onClick={() => setModalOpen(true)}
            >
              <svg
                className="md:w-12 md:h-12 h-10 w-10 text-green-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                  clipRule="evenodd"
                />
                <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
              </svg>
              <div className="text-sm md:text-base text-center font-semibold text-gray-800">
                Kalender verschenken
              </div>
            </div>
            <CalendooModal
              isOpen={modalOpen}
              onRequestClose={() => {
                setModalOpen(false);
                setIsCopied(false);
              }}
              title="Kalender verschenken"
            >
              <div className="flex flex-col justify-start items-center max-w-lg p-3 md:p-5">
                <div className="text-gray-800 text-base md:text-lg">
                  Kopiere den folgenden Link und schicke ihn an den Glücklichen
                  oder die Glückliche:
                </div>
                <div className="flex flex-col justify-center items-center h-full mt-5 md:mt-10">
                  <div
                    className="text-green-100 bg-green-800 px-3 py-3 rounded-lg flex justify-center items-center"
                    onClick={() => {
                      const calendarLink = calendarLinkInputRef.current;
                      if (!calendarLink) return;

                      navigator.clipboard.writeText(calendarLink.value);
                      setIsCopied(true);
                    }}
                  >
                    <div className="">
                      <svg
                        className="w-6 h-6 md:w-8 md:h-8 mr-3 select-none"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
                        <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
                      </svg>
                    </div>
                    <input
                      className="text-sm md:text-base text-center break-all bg-transparent hidden"
                      ref={calendarLinkInputRef}
                      value={`${process.env.REACT_APP_HOST_URL}/viewCalendar/${calendarId}?calendarToken=${currentCalendar?.token}`}
                      readOnly
                    />
                    <div className="text-sm md:text-base text-center break-all">
                      Hier klicken zum Kopieren
                    </div>
                  </div>
                  {copied && (
                    <div className="text-sm md:text-base text-gray-800">
                      Kalender Link wurde kopiert!
                    </div>
                  )}
                </div>
              </div>
            </CalendooModal>
          </div>
          <AnimatePresence>
            {showConfetti && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 3 }}
              >
                <Confetti
                  width={width}
                  height={height}
                  colors={['#B91646', '#DFD8CA', '#FBF3E4', '#105652']}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default Doors;

import React from 'react';
import { useReceivedCalendars } from '../hooks/hook.useReceivedCalendars';
import Calendars from './Calendars';

const ReceivedCalendars = () => {
  const { receivedCalendars } = useReceivedCalendars();
  return <Calendars calendars={receivedCalendars} doorLink="viewCalendar" />;
};

export default ReceivedCalendars;

import { useStoreActions } from '../hooks';
import { useQuery } from 'react-query';
import { CheckAuth, headers } from '../components/Api';
import { Door } from 'src/Store';

export const useDoors = (calendarId: string | undefined) => {
  const logout = useStoreActions((action) => action.currentUser.logout);
  const getDoors = (
    key: string,
    id: { id: string | undefined },
  ): Promise<Door[]> => {
    const uri =
      id?.id !== undefined
        ? process.env.REACT_APP_API_ENDPOINT + '/Door/GetAll/' + id.id
        : process.env.REACT_APP_API_ENDPOINT + '/Door/GetAll';
    return fetch(uri, {
      method: 'GET',
      mode: 'cors',
      headers: headers(),
    })
      .then((response) => CheckAuth(response, logout))
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          console.log(response.message);
        }
      });
  };
  const getDoorsQuery = useQuery(['getDoors', { id: calendarId }], getDoors);
  return {
    doors: getDoorsQuery.data ?? [],
    doorsQuery: getDoorsQuery,
  };
};

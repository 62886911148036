import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import Modal from 'react-modal';

const CalendooModal: React.FC<{
  isOpen: boolean;
  onRequestClose: CallableFunction;
  title?: string;
}> = ({ isOpen, children, onRequestClose, title }) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      overlayClassName="bg-gray-800 top-0 bottom-0 right-0 left-0 fixed flex justify-center items-center"
      className="bg-gray-300 bottom-auto absolute rounded-lg focus:outline-none max-w-xs md:max-w-none"
      style={{
        content: {},
        overlay: { backgroundColor: 'rgba(0,0,0,0.5)' },
      }}
      onRequestClose={() => onRequestClose()}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
          className=""
        >
          <div className="">
            {title && (
              <>
                <div className="text-xl md:text-2xl font-light text-center mt-3 px-3">
                  {title}
                </div>
                <div className="border-gray-400 border-b mt-3 h-1"></div>
              </>
            )}
            {children}
          </div>
        </motion.div>
      </AnimatePresence>
    </Modal>
  );
};

export default CalendooModal;

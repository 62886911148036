import React from 'react';
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../hooks';
import Link from './Link';
import { useParams, useHistory } from 'react-router-dom';
import { queryCache, useQuery } from 'react-query';
import { getDoor, markDoorAsOpen } from './Api';
import moment from 'moment';
import { AnimatePresence, motion } from 'framer-motion';
import CalendooModal from './CalendooModal';
import Content from './Content';
import CloseModal from './CloseModal';

const ViewContents = () => {
  const { doorId }: { doorId?: string | undefined } = useParams();
  const history = useHistory();
  const idInt = parseInt(doorId ?? '');

  const contents = useStoreState((state) =>
    state.contents.items.filter((c) => c.doorId == idInt),
  );
  const getContentsApi = useStoreActions(
    (actions) => actions.contents.getContentsApi,
  );
  const pushContents = useStoreActions(
    (actions) => actions.contents.pushContents,
  );

  const getDoorQuery = useQuery(['getDoor', { id: doorId }], getDoor);
  const currentDoor = getDoorQuery.data;

  const forbiddenToOpen = moment().isBefore(currentDoor?.dueDate);

  useEffect(() => {
    getContentsApi(idInt).then((res) => {
      if (
        currentDoor?.dueDate &&
        !currentDoor?.opened &&
        !moment().isBefore(currentDoor?.dueDate)
      ) {
        markDoorAsOpen(doorId);
      }
      pushContents(res);
    });
  }, [doorId, idInt, currentDoor?.dueDate]);

  return (
    <CalendooModal
      isOpen={true}
      onRequestClose={() => {
        history.goBack();
      }}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          className="h-128 md:h-168 w-128 l:w-168 max-w-xs s:max-w-none scrolling-touch overflow-x-hidden p-3"
          onClick={(e) => e.stopPropagation()}
        >
          <CloseModal />
          {forbiddenToOpen ? (
            <div className="flex h-full flex-col text-center justify-center items-center">
              <div className="leading-none text-lg md:text-2xl text-gray-600 font-semibold tracking-wide">
                Du musst dich noch ein wenig gedulden.
              </div>
            </div>
          ) : contents.length === 0 ? (
            <div className="flex h-full flex-col text-center justify-center items-center w-full">
              <div className="leading-none text-base md:text-xl text-gray-600 font-normal tracking-wide">
                Hinter diesem Türchen befindet sich leider nichts :(
              </div>
            </div>
          ) : (
            <div className="flex flex-1 flex-col text-center justify-start items-center w-full">
              <div className="flex w-full flex-col flex-grow justify-center items-center mt-5">
                <div className="text-gray-800 mb-5">
                  <span className="text-2xl md:text-4xl font-bold">
                    {getDoorQuery.data &&
                      moment(currentDoor?.dueDate).format('D.')}
                  </span>
                  <span className="text-xl md:text-2xl">
                    {getDoorQuery.data &&
                      moment(currentDoor?.dueDate).format(' MMMM')}
                  </span>
                </div>
                {contents
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((content, i) => {
                    return (
                      <Content
                        key={content.id}
                        content={content}
                        isLast={i === contents.length - 1}
                        i={i}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </CalendooModal>
  );
};

export default ViewContents;

import React from 'react';

interface LoginPrimaryButtonProps {
  caption: string;
  onClickFunction?: CallableFunction;
  disabled?: boolean;
}

const LoginPrimaryButton: React.FC<LoginPrimaryButtonProps> = ({
  onClickFunction,
  caption,
  disabled,
}) => {
  return (
    <button
      className={
        `focus:outline-none outline-none md:hover:scale-110 transform duration-200 bg-gradient-to-r from-green-800 to-green-700 
      text-green-100 mt-10 py-3 px-4 w-full text-sm tracking-wide font-bold rounded-md ` +
        (disabled ? 'opacity-50' : '')
      }
      onClick={(e) => (onClickFunction != undefined ? onClickFunction(e) : '')}
      type="submit"
    >
      <span className="">{caption}</span>
    </button>
  );
};

export default LoginPrimaryButton;

import React from 'react';
import LoginRouter from './LoginRouter';

const AuthApp: React.FC = () => {
  return (
    <div className="bg-gray-100 h-full">
      <LoginRouter />
    </div>
  );
};

export default AuthApp;

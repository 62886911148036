import React from 'react';
import CalendarListItem from './CalendarListItem';
import { Link, useRouteMatch } from 'react-router-dom';
import { Calendar } from 'src/Store';

interface CalendarsProps {
  calendars: Calendar[];
  doorLink: string;
}

const Calendars: React.FC<CalendarsProps> = ({ calendars, doorLink }) => {
  const { path, url } = useRouteMatch();
  return (
    <div className="l:px-0">
      <div className="grid s:grid-cols-2 md:grid-cols-3 l:grid-cols-4 gap-4 max-w-screen-l m-auto relative">
        {doorLink === 'calendar' && (
          <div className="absolute top-0 left-0 -ml-16 hidden md:block">
            <Link to={`${url}/addCalendar`}>
              <div className=" h-16 w-16 p-4 cursor-pointer">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="fill-current rounded-full text-gray-700 bg-gray-200 md:hover:scale-110 transform duration-200 shadow-lg ease-in-out"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            </Link>
          </div>
        )}

        {calendars.map((c, i) => {
          return (
            <Link key={i} to={doorLink + '/' + c.id}>
              <CalendarListItem calendar={c} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Calendars;

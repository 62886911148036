import React from 'react';

const LoadingIndicator = () => {
  return (
    <div
      className="w-8 h-8 bg-gray-800 rounded-lg"
      style={{ animation: 'loading 1s ease-in-out alternate infinite' }}
    ></div>
  );
};

export default LoadingIndicator;

import React from 'react';

interface LoginSecondaryButtonProps {
  caption: string;
  onClickFunction: CallableFunction;
}

const LoginSecondaryButton: React.FC<LoginSecondaryButtonProps> = ({
  onClickFunction,
  caption,
}) => {
  return (
    <button
      type="button"
      className="mt-5 tracking-wide outline-none font-bold text-sm md:hover:scale-110 focus:outline-none transform duration-500"
      onClick={() => onClickFunction()}
    >
      {caption}
    </button>
  );
};

export default LoginSecondaryButton;

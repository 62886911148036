import React, { useState } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { queryCache } from 'react-query';
import { Content } from 'src/Store';
import { useStoreActions } from '../hooks';
import { useUserInfo } from '../hooks/hook.useUserInfo';
import CalendooModal from './CalendooModal';
import DeleteButton from './IconButton';
import Link from './Link';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

const SimpleContent: React.FC<{
  content: Content;
  isLast: boolean;
  i: number;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
}> = ({ content, isLast, i, provided, snapshot }) => {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const deleteContentApi = useStoreActions(
    (actions) => actions.contents.deleteContentApi,
  );

  const pushContents = useStoreActions(
    (actions) => actions.contents.pushContents,
  );

  const { info } = useUserInfo();
  const fileExtensionType = content.fileExtension?.split('/')[0].toLowerCase();
  const received = info.nameid !== content?.createdById.toString();
  //Trigger deployment.
  return (
    <div
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      key={i}
      className={
        'flex w-full justify-center items-center ' +
        (isLast ? 'mt-3 mb-5' : i > 0 ? 'mt-3' : '') +
        ' ' +
        (snapshot?.isDragging ? '' : '')
      }
    >
      {!received && (
        <div>
          <div className="w-8 transform group mr-2">
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              className="rotate-90 fill-current rounded-full opacity-50 text-green-800 md:group-hover:scale-110 transform duration-200 ease-in-out"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      )}

      {content.contentType === 'file' && (
        <>
          {fileExtensionType === 'video' && (
            // <div className="w-full md:w-128">
            //   <VideoContent content={content} />
            // </div>
            <div className="w-56 md:w-128 h-64">
              <video
                width="100%"
                height="auto"
                controls={true}
                className="rounded-lg h-full flex justify-center items-center border outline-none bg-black"
              >
                <source src={content.text} type={content.fileExtension} />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          {fileExtensionType === 'audio' && (
            <div className="w-full md:w-128">
              <audio
                controls
                className="border rounded-full w-full outline-none"
              >
                <source src={content.text} type={content.fileExtension} />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
          {fileExtensionType === 'image' && (
            <div className="w-full md:w-128">
              <img
                onClick={() => setModalOpen(true)}
                src={content.text}
                className={'rounded-lg w-full h-auto border'}
              />
              <CalendooModal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
              >
                <img
                  src={content.text}
                  style={{ maxHeight: '80vh' }}
                  className={
                    'rounded-lg w-full h-auto md:h-full md:w-auto border'
                  }
                />
              </CalendooModal>
            </div>
          )}
          {!['video', 'audio', 'image'].includes(fileExtensionType) && (
            <div className="w-full md:w-128 flex flex-col items-center">
              <Link caption="Datei herunterladen" link={content.text} />
            </div>
          )}
        </>
      )}
      {content.contentType === 'text' && (
        <div className="w-full md:w-128">
          <div
            className={
              'w-full h-full bg-white rounded-lg border p-3 text-gray-800 text-lg font-medium font-sourceSansPro whitespace-pre-wrap '
            }
          >
            {content.text}
          </div>
        </div>
      )}
      {content.contentType === 'link' && (
        <div className="w-full md:w-128 flex flex-col items-center">
          <Link caption="Klick mich!" link={content.text} />
        </div>
      )}
      {!received && (
        <div className="ml-2">
          <DeleteButton inverted cb={() => setConfirmIsOpen(true)} />
        </div>
      )}

      <CalendooModal
        isOpen={confirmIsOpen}
        onRequestClose={() => {
          setConfirmIsOpen(false);
        }}
        title="Wirklich löschen?"
      >
        <div className="p-5 md:w-128 flex">
          <PrimaryButton
            caption="Löschen"
            onClickFunction={() =>
              deleteContentApi(content.id).then((res) => {
                pushContents(res);
                queryCache.invalidateQueries('getFiles');
                queryCache.invalidateQueries('getDoors');
              })
            }
          />
          <SecondaryButton
            caption="Abbrechen"
            onClickFunction={() => setConfirmIsOpen(false)}
          />
        </div>
      </CalendooModal>
    </div>
  );
};

export default SimpleContent;

import React from 'react';
import { Link } from 'react-router-dom';
import DashboardIcon from './DashboardIcon';
import DashboardText from './DashboardText';

interface DashboardTileProps {
  type: 'icon' | 'text';
  caption: string;
  linkTo?: string | undefined;
  disabled?: boolean;
}

interface WrapperProps {
  linkTo?: string | undefined;
  className?: string;
  disabled?: boolean;
}

const Wrapper: React.FC<WrapperProps> = (props) => {
  return props.linkTo && !props.disabled ? (
    <Link className={props.className} to={props.linkTo}>
      {props.children}
    </Link>
  ) : (
    <div className={props.className}>{props.children}</div>
  );
};

const DashboardTile: React.FC<DashboardTileProps> = (props) => {
  return (
    <Wrapper className="col-span-1" {...props}>
      <div
        className={
          'bg-gray-300 shadow-lg rounded-lg h-40 md:h-48 flex flex-col justify-center text-center ' +
          'items-center cursor-pointer transform duration-200 md:hover:scale-105 l:hover:scale-110 px-2 ' +
          (props.disabled ? 'opacity-50' : '')
        }
      >
        {props.type === 'icon' ? (
          <DashboardIcon caption={props.caption}>
            {props.children}
          </DashboardIcon>
        ) : (
          <DashboardText caption={props.caption}>
            {props.children}
          </DashboardText>
        )}
      </div>
    </Wrapper>
  );
};

export default DashboardTile;

import React, { useState } from 'react';
import { useCallback } from 'react';
import { useStoreActions, useStoreState } from '../hooks';
import { useDropzone } from 'react-dropzone';
import { queryCache, useMutation } from 'react-query';
import LoadingIndicator from './LoadingIndicator';

interface DropzoneProps {
  doorId: number;
  fileSizeSum?: number;
  fileSizeLimit?: number;
}

const Dropzone = (props: React.PropsWithChildren<DropzoneProps>) => {
  const [error, setError] = useState<string>('');
  const contentType = useStoreState((state) => state.contentForm.contentType);
  const formContent = useStoreState((state) => state.contentForm.content);
  const filesToAdd = useStoreState((state) => state.contentForm.files);
  const uploadFile = useStoreActions(
    (actions) => actions.contents.uploadFileApi,
  );
  const [uploadFileMutation, { isLoading }] = useMutation(uploadFile);
  const setFiles = useStoreActions((actions) => actions.contentForm.setFiles);
  const setContent = useStoreActions(
    (actions) => actions.contentForm.setContent,
  );
  const setContentType = useStoreActions(
    (actions) => actions.contentForm.changeType,
  );
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    const files = acceptedFiles as File[];
    const totalUploadFileSize = files
      .map((f) => f.size)
      .reduce((a, b) => a + b, 0);
    if (
      props.fileSizeLimit !== undefined &&
      props.fileSizeLimit < (props.fileSizeSum ?? 0) + totalUploadFileSize
    ) {
      setError('Der Kalender ist nicht groß genug für diese Datei');
      return;
    }
    uploadFileMutation({
      id: props.doorId,
      files: acceptedFiles,
      contentType: contentType,
      formContent: formContent,
    }).then(() => {
      setFiles([]);
      setError('');
      setContent('');
      setContentType('');
      queryCache.invalidateQueries('getFiles');
      queryCache.invalidateQueries('getDoors');
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: 'image/*,audio/*,video/*',
  });

  return (
    <>
      <div
        {...getRootProps()}
        className={
          'rounded-lg border-gray-800 border-dashed border hover:bg-gray-400 w-full text-gray-800 outline-none ' +
          ' cursor-pointer flex justify-center h-32 md:h-40 items-center font-normal text-2xl md:text-3xl ' +
          (isDragActive ? 'bg-gray-400' : '')
        }
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <span>{filesToAdd.length > 0 ? filesToAdd[0].name : 'Dropzone'}</span>
        )}
        {error !== '' && (
          <div className="tracking-normal text-lg mt-3 px-5">
            <span>{error}</span>
          </div>
        )}

        <input {...getInputProps()} />
      </div>
    </>
  );
};

export default Dropzone;

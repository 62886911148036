import React from 'react';
import { getDoors } from './Api';
import DashboardTile from './DashboardTile';
import { useQuery } from 'react-query';
import { useReceivedCalendars } from '../hooks/hook.useReceivedCalendars';
import { useCalendars } from '../hooks/hook.useCalendars';
import LoadingIndicator from './LoadingIndicator';
import { useUserInfo } from '../hooks/hook.useUserInfo';

const Dashboard = () => {
  const userInfo = useUserInfo();
  const { calendars, calendarQuery } = useCalendars();
  const getDoorsQuery = useQuery(['getDoors', { id: undefined }], getDoors);
  const { receivedCalendars, receivedCalendarsQuery } = useReceivedCalendars();
  const doors = getDoorsQuery.data;
  const totalDoorsCount = doors?.length || 0;
  const opendDoorsCount = doors?.filter((d) => d.opened).length || 0;
  const latestCalendar = calendars?.sort(
    (a, b) => a?.createdAt?.getDate() - b?.createdAt?.getDate(),
  )[calendars.length - 1];

  const calendarsLoading = calendarQuery.isLoading;
  const doorsLoading = getDoorsQuery.isLoading;
  const receivedLoading = receivedCalendarsQuery.isLoading;

  return (
    <div className="grid grid-cols-2 l:grid-cols-4 gap-4 max-w-screen-l m-auto">
      <DashboardTile
        linkTo="/createdCalendars"
        caption="Kalender erstellt"
        type="text"
        disabled={calendars.length <= 0}
      >
        {calendarsLoading ? <LoadingIndicator /> : calendars.length}
      </DashboardTile>

      <DashboardTile
        linkTo={`/addCalendar`}
        caption="Kalender erstellen"
        type="icon"
      >
        {
          <svg
            className="fill-current text-gray-800 h-8 w-8 md:h-12 md:w-12"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z"
              clipRule="evenodd"
            ></path>{' '}
          </svg>
        }
      </DashboardTile>

      <DashboardTile
        linkTo={'/calendar/' + latestCalendar?.id}
        type="icon"
        caption="Kalender weiter befüllen"
        disabled={latestCalendar?.id === undefined}
      >
        <svg
          className="fill-current text-gray-800 h-8 w-8 md:h-12 md:w-12"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>{' '}
        </svg>
      </DashboardTile>

      <DashboardTile
        linkTo="/receivedCalendars"
        caption="Erhaltene Kalender"
        type="text"
        disabled={receivedCalendars.length <= 0}
      >
        {receivedLoading ? <LoadingIndicator /> : receivedCalendars.length}
      </DashboardTile>

      <DashboardTile caption="Türchen wurden geöffnet" type="icon">
        {doorsLoading ? (
          <LoadingIndicator />
        ) : (
          `${opendDoorsCount}/${totalDoorsCount}`
        )}
      </DashboardTile>
      {userInfo.isAdmin && (
        <DashboardTile
          caption="Admin Dashboard"
          type="text"
          linkTo="/adminDashboard"
        >
          <svg
            className="fill-current text-gray-800 h-8 w-8 md:h-12 md:w-12"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
            />
          </svg>
        </DashboardTile>
      )}
    </div>
  );
};

export default Dashboard;

import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import background from '../res/wallpaper1.jpg';
import Doors from './Calendar';
import Login from './Login';
import Register from './Register';
import TokenLogin from './TokenLogin';

const LoginRouter = () => {
  const location = useLocation();
  return (
    <div
      className="flex flex-col justify-center items-center h-screen bg-gradient-to-br from-green-900 to-green-700 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${background})` }}
    >
      <AnimatePresence exitBeforeEnter>
        <div
          className="text-5xl md:text-6xl text-gray-300 font-extrabold"
          style={{ textShadow: 'rgba(0, 0, 0, 0.5) 0 0 100px' }}
        >
          calendoo
        </div>
        <Switch key={location.pathname}>
          <Route path="/Register">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="register"
            >
              <Register />
            </motion.div>
          </Route>
          <Route path="/viewCalendar/:calendarId">
            <>
              <TokenLogin />
              <Doors />
            </>
          </Route>
          <Route path="/">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="login"
            >
              <Login />
            </motion.div>
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
};

export default LoginRouter;

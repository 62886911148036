import { useStoreActions } from '../hooks';
import { useQuery } from 'react-query';
import { Calendar } from 'src/Store';
import { CheckAuth, headers } from '../components/Api';

export const useCalendars = () => {
  const logout = useStoreActions((action) => action.currentUser.logout);
  const getCalendars = (): Promise<Calendar[]> => {
    return fetch(process.env.REACT_APP_API_ENDPOINT + '/Calendar/GetAll', {
      method: 'GET',
      mode: 'cors',
      headers: headers(),
    })
      .then((response) => CheckAuth(response, logout))
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          console.log(response.message);
        }
      });
  };
  const getCalendarsQuery = useQuery('getCalendars', getCalendars);
  return {
    calendars: getCalendarsQuery.data ?? [],
    calendarQuery: getCalendarsQuery,
  };
};

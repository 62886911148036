import React, { createContext, FunctionComponent, useState } from 'react';
import background from '../res/wallpaper1.jpg';
2;
const useAppContextState = () => {
  const [imageUrl, setImageUrl] = useState(background);

  return {
    imageUrl,
    setImageUrl,
  };
};

type IAppContext = ReturnType<typeof useAppContextState>;

export const AppContext = createContext({} as IAppContext);

export const AppContextProvider: FunctionComponent = ({ children }) => {
  const context: IAppContext = useAppContextState();
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

import React from 'react';

interface DashboardIconProps {
  caption: string;
}

const DashboardIcon: React.FC<DashboardIconProps> = ({ children, caption }) => {
  return (
    <>
      <div className="text-gray-800 text-3xl md:text-5xl font-semibold leading-none font-mono">
        {children}
      </div>
      <span className="text-gray-800 mt-3">&nbsp;{caption}</span>
    </>
  );
};

export default DashboardIcon;

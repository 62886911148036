import { useQuery } from 'react-query';
import { getReceivedCalendars } from '../components/Api';

export const useReceivedCalendars = () => {
  const getReceivedCalendarsQuery = useQuery(
    ['getReceivedCalendars'],
    getReceivedCalendars,
  );
  return {
    receivedCalendars: getReceivedCalendarsQuery.data ?? [],
    receivedCalendarsQuery: getReceivedCalendarsQuery,
  };
};
